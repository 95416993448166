/* ==============================
		  mylist.scss
================================= */
.sectionCover-mylist {
  margin-top: 87px;
}

.sectionTtl-mylist {
  font-size: 20px;
  text-indent: 3px;
}

.mylistNum {
  font-size: 35px;
  display: inline-block;
  margin: 0 4px 0 6px;
  vertical-align: bottom;
  line-height: 28px;
}

.myListSection {
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1023px) {
  .myListSection {
    display: block;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.myListSectionInner {
  width: 40%;
  max-width: 485px;
  padding: 30px 0;
}

@media screen and (max-width: 1023px) {
  .myListSectionInner {
    width: 100%;
    max-width: none;
  }
}

@media screen and (max-width: 1023px) {
  .myListSectionInner + .myListSectionInner {
    margin-top: 100px;
  }
}

.myListSection {
  position: relative;
}

.myListSection::before {
  position: absolute;
  content: "";
  width: 4px;
  height: 100%;
  background-color: #D1D1D1;
  left: calc(50% - 2px);
}

@media screen and (max-width: 1023px) {
  .myListSection::before {
    width: 100%;
    height: 4px;
    left: 0;
    top: 30%;
  }
}

.myListSection::after {
  position: absolute;
  content: "OR";
  border-radius: 50%;
  border: 4px solid #D1D1D1;
  background-color: #FFF;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  color: #D1D1D1;
  font-family: 'Manrope', sans-serif;
  font-size: 20px;
  font-weight: bold;
  left: calc(50% - 24px);
  top: calc(30% - 24px);
}

.snsBtn {
  display: block;
  width: 100%;
  line-height: 90px;
  border-radius: 5px;
  background-color: #1DB91C;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  position: relative;
}

.snsBtn::before {
  position: absolute;
  content: '';
  display: inline-block;
  width: 42px;
  height: 40px;
  background-image: url("../img/common/icon_line-login.svg");
  background-size: contain;
  vertical-align: middle;
  background-repeat: no-repeat;
  left: 18px;
  top: calc(50% - 20px);
}

.snsBtn:hover {
  opacity: 0.8;
}

@media screen and (max-width: 1023px) {
  .snsBtn {
    font-size: 18px;
  }
}

.snsBtn-twitter {
  background-color: #1DA1F2;
}

.snsBtn-twitter::before {
  width: 37.5px;
  height: 30.5px;
  background-image: url("../img/common/icon_twitter-login.svg");
  left: 23px;
  top: calc(50% - 15px);
}

.snsBtn-facebook {
  background-color: #3B5997;
}

.snsBtn-facebook::before {
  width: 37.7px;
  height: 37.5px;
  background-image: url("../img/common/icon_facebook-login.svg");
  left: 23px;
  top: calc(50% - 18px);
}

.snsBtn + .snsBtn {
  margin-top: 25px;
}

dl + dl {
  margin-top: 15px;
}

dt {
  font-size: 18px;
  font-weight: bold;
}

@media screen and (max-width: 1023px) {
  dt {
    font-size: 16px;
    width: 100%;
  }
}

dd {
  font-size: 18px;
}

@media screen and (max-width: 1023px) {
  dd {
    font-size: 16px;
  }
}

.input {
  border: 0px;
  padding: 20px 25px;
  background-color: #F7F7F7;
  border-radius: 10px;
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .input {
    padding: 20px 10px;
  }
}

.loginTxtCover {
  text-align: center;
}

.loginTxt {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  text-decoration: underline;
  color: #191919;
  font-weight: bold;
}

.loginTxt:hover {
  opacity: 0.7;
}

.tml-label {
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
}

@media screen and (max-width: 1023px) {
  .tml-label {
    font-size: 16px;
  }
}

.tml-field {
  border: 0px;
  padding: 20px 25px;
  background-color: #F7F7F7;
  border-radius: 10px;
  width: 100%;
  margin: 0 0 20px;
}

@media screen and (max-width: 1023px) {
  .tml-field {
    padding: 20px 10px;
  }
}

.tml-button {
  display: inline-block;
  background-color: #3B3B3B;
  border-radius: 32.5px;
  height: 65px;
  width: 325px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  transition: 0.5s;
  border: 2px solid #3B3B3B;
  margin: 30px auto;
}

@media screen and (max-width: 800px) {
  .tml-button {
    width: 100%;
  }
}

.tml-button::after {
  position: absolute;
  content: '';
  display: inline-block;
  width: 10px;
  height: 20px;
  background-image: url("../img/common/arrow-right-white.svg");
  background-size: contain;
  vertical-align: middle;
  background-repeat: no-repeat;
  right: 15px;
  top: calc(50% - 10px);
}

.ml-links {
  text-align: center;
}

.tml-register-link, .tml-lostpassword-link {
  display: block;
  margin: 0 auto;
  text-align: center;
  text-decoration: underline;
  color: #191919;
  font-weight: bold;
}

.tml-register-link:hover, .tml-lostpassword-link:hover {
  opacity: 0.7;
}

.signinSection {
  max-width: 1000px;
  width: 95%;
  margin: 75px auto;
}

@media screen and (max-width: 1023px) {
  .signinSection {
    margin: 40px auto 75px;
  }
}

.signinInfoArea {
  border: 2px solid #000;
  border-radius: 10px;
  width: 95%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px;
  background-color: #fff;
}

@media screen and (max-width: 1023px) {
  .signinInfoArea {
    padding: 20px 10px;
  }
}

.signinInfo {
  width: 60%;
  margin: 0 auto;
}

@media screen and (max-width: 1023px) {
  .signinInfo {
    width: 95%;
  }
}

.signinInfo + .signinInfo {
  margin-top: 30px;
}

.btnArea-signup {
  width: 60%;
  margin: 50px auto 0;
}

@media screen and (max-width: 1023px) {
  .btnArea-signup {
    display: flex;
    width: 95%;
  }
}

.btnArea-signup .moreBtn {
  width: 47%;
}

.mylistTxt {
  max-width: 1000px;
  width: 95%;
  text-align: center;
  margin: 0 auto 40px;
  font-weight: bold;
}

.btnArea-signin {
  margin: 60px auto 100px;
}
